import { ITrboBuehneResponse } from '../Checkout/Models/Trbo/ITrboResonse';
import { ProduktkarussellModel } from '../Produktdetails/Models/Produktkarussell';
import { CreateSliderContent } from '../Shared/Slider';
import { configureSwiperSlider, initSwiperLarge, initSwiperSmall } from '../swiper/swiperconfig';
import {
    MerklisteKarusellInViewTracking,
    trackIdLayerReduced,
    tracking_eecPromotionview,
    trackNewsletterRegisterEvent,
} from '../Tracking/GoogleTracking';
import { buildTrboRequestUrl } from './Trbo/TrboRequestBuilder';
import { mapTrboResponseToProduktkarussellModel } from './Trbo/TrboResponseMapper';

declare var __ucCmp: any;
declare var grecaptcha: any;
const trboId = 'ryxCCtrEX';

$(() => {
    ///click button "Newsletter abbonieren"
    $('.submitNewsletter').click(function(event) {
        var validations = {
            email: [/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/],
        };
        var newsletterEntry = {};
        var params = [];

        //set default validation values
        $('#Newsletter_Email_Validation').addClass('hide');
        $('#Newsletter_Email_Success').addClass('hide');
        $('#Newsletter_Email_Server_Error').addClass('hide');

        if (grecaptcha == undefined) {
            $('#Newsletter_Recaptcha_Validation').removeClass('hide');
            return false;
        }
        try {
            var recaptchaResponse = grecaptcha.getResponse();
        } catch {
            $('#Newsletter_Recaptcha_Validation').removeClass('hide');
            return false;
        }
        if (recaptchaResponse.length == 0) {
            $('#Newsletter_Recaptcha_Validation').removeClass('hide');
            return false;
        }

        var email = <HTMLInputElement>$('#newsletter_Email')[0];
        var trackingallowed = $('#TermsAndConditionsAccepted').prop('checked');
        var listNameInxmail = $('#listNameInxmail').val();
        var trackingPermissionMainButtonValue = $('#trackingPermissionMainButtonValue').val();
        var additionalParamsJson = $('#additionalParams').val();
        if (additionalParamsJson) {
            var additionParamsList = $.parseJSON(additionalParamsJson.toString());
            if (additionParamsList) {
                $.each(additionParamsList, function(index, value) {
                    params.push({ key: index, value: value });
                });
            }
        }

        //erweitertes form
        var extendedForm = $('#extendedFormValue').val();
        if (extendedForm) {
            var salutation = $('#newsletter_Salutation').val();
            var firstname = $('#newsletter_Firstname').val();
            var lastname = $('#newsletter_Lastname').val();
        }

        //set regex for email validation
        var validation = new RegExp(validations['email'][0]);

        //validate email
        if (!validation.test(email.value)) {
            $('#Newsletter_Email_Validation').removeClass('hide');
            return false;
        } else {
            email.setCustomValidity('');
            newsletterEntry['Email'] = email == null ? '' : email.value;
            newsletterEntry['Salutation'] = salutation == null ? '' : salutation;
            newsletterEntry['Firstname'] = firstname == null ? '' : firstname;
            newsletterEntry['Lastname'] = lastname == null ? '' : lastname;
            newsletterEntry['IsTrackingAllowed'] = trackingallowed == null ? '' : trackingallowed;
            newsletterEntry['ListNameInxmail'] = listNameInxmail == null ? '' : listNameInxmail;
            newsletterEntry['TrackingPermissionMainButtonValue'] =
                trackingPermissionMainButtonValue == null ? '' : trackingPermissionMainButtonValue;
            newsletterEntry['AdditionalParameters'] = params == null ? '' : params;
            newsletterEntry['RecaptchaResponse'] = recaptchaResponse;

            const datalayerLabel = $(this).data('label');

            //send data to API
            if (extendedForm) {
                $.post('/Umbraco/Api/Newsletter/SendNewsletterEmailExtended', newsletterEntry, async function(
                    data,
                    status
                ) {
                    $('#Newsletter_Email_Success').removeClass('hide');
                    trackNewsletterRegisterEvent(datalayerLabel);
                    if (data) {
                        await trackIdLayerReduced(data, 'idLayer_reduced_nl-registrierung');
                    }
                }).fail(function(data) {
                    $('#Newsletter_Email_Server_Error').removeClass('hide');
                });
            } else {
                $.post('/Umbraco/Api/Newsletter/SendNewsletterEmail', newsletterEntry, async function(data, status) {
                    $('#Newsletter_Email_Success').removeClass('hide');
                    trackNewsletterRegisterEvent(datalayerLabel);
                    if (data) {
                        await trackIdLayerReduced(data, 'idLayer_reduced_nl-registrierung');
                    }
                }).fail(function(data) {
                    $('#Newsletter_Email_Server_Error').removeClass('hide');
                });
            }
        }
    });

    var loginFlag = $('#loggedInFlag');

    if (loginFlag && loginFlag.length > 0 && loginFlag.val && $('#merklisteKarusell').length) {
        ShowMerklisteProductsInKarusell();
    }

    function ShowMerklisteProductsInKarusell() {
        $.ajax({
            method: 'GET',
            dataType: 'JSON',
            url: '/Umbraco/api/MerklisteKarusellApi/GetMerklisteProducts',
            success: async data => {
                await MerklisteKarusellInViewTracking();
                var products = data as ProduktkarussellModel;
                if (products != null && products.karussellItems.length > 0) {
                    $('.slider').removeClass('hide');

                    CreateSliderContent(
                        products,
                        $('#merklisteSlider'),
                        $('#MerklistePrototypes #SliderPrototypes'),
                        'merklisteSlider'
                    );
                    //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                    let topslider = initSwiperLarge($('#produktkarussellLarge'));

                    topslider.update();
                    setTimeout(function() {
                        // nasty hack aber keinen weg gefunden das karussell auf seite 1 springt...
                        $('.slider .swiper-pagination-bullet')
                            .first()
                            .click();
                    }, 100);
                } else {
                    $('.slider').addClass('hide');
                }
            },
            error: () => {
                $('.slider').addClass('hide');
            },
        });
    }

    hideTrboSliders();
});

async function hideTrboSliders() {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        var trboSliders = $('.trbo-grid-caroussel');
        var trboDynamicSliders = $('.trboDynamicSlider');
        if (trboSliders.length || trboDynamicSliders.length) {
            renderTrboSliders();
            renderDynamicTrboSliders();
        }
        handleTrboBuehnen();
    } else {
        setTimeout(hideTrboSliders, 100);
    }
}

async function handleTrboBuehnen() {
    var trboDynamicBuehnen = $('.trboDynamicBuehne');
    if (trboDynamicBuehnen.length) {
        if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
            const details = await __ucCmp.getConsentDetails();
            if (details.services[trboId].consent.given && window['_trbo'] === undefined) {
                setTimeout(handleTrboBuehnen, 100);
                return;
            }

            trboDynamicBuehnen.each(function() {
                if (!details.services[trboId].consent.given) {
                    $(this)
                        .find('.bannerItem')
                        .removeClass('hidden');
                    $(this)
                        .find('.promotionTracking')
                        .removeClass('hidden');
                    configureSwiperSlider($(this).data('frequency'), 'fade', $(this));
                    document.dispatchEvent(new Event('scroll'));
                    window.scroll(0, 1); // hack to show slider correctly
                    tracking_eecPromotionview();
                    return;
                }
                const slotName = $(this).data('trboslotname');
                const requestUrl = buildTrboRequestUrl(slotName);
                $.ajax({
                    method: 'GET',
                    dataType: 'JSON',
                    url: encodeURI(requestUrl),
                    success: async data => {
                        const trboResponse = data as ITrboBuehneResponse;
                        if (!trboResponse || !trboResponse.data || trboResponse.data.length === 0) {
                            $(`.${slotName}`)
                                .find('.bannerItem')
                                .removeClass('hidden');
                            $(`.${slotName}`)
                                .find('.promotionTracking')
                                .removeClass('hidden');
                            configureSwiperSlider($(`.${slotName}`).data('frequency'), 'fade', $(`.${slotName}`));
                            document.dispatchEvent(new Event('scroll'));
                            window.scroll(0, 1); // hack to show slider correctly
                            await tracking_eecPromotionview();
                            return;
                        }

                        $(`.${slotName}`)
                            .find('.serverItem')
                            .remove();
                        $(`.${slotName}`)
                            .find('.trboBuehneDummy')
                            .removeClass('hidden');
                        $(`.${slotName}`)
                            .find('.trboBuehneDummy a')
                            .attr('href', trboResponse.data[0].target_url);
                        $(`.${slotName}`)
                            .find('.trboBuehneDummy .desktop img')
                            .attr('src', trboResponse.data[0].desktop_url);
                        $(`.${slotName}`)
                            .find('.trboBuehneDummy .tablet img')
                            .attr('src', trboResponse.data[0].tablet_url);
                        $(`.${slotName}`)
                            .find('.trboBuehneDummy .smartphones img')
                            .attr('src', trboResponse.data[0].mobile_url);

                        if (trboResponse.tracking && trboResponse.tracking.length > 0) {
                            $(`.${slotName} .trboBannerClickTracking`)
                                .attr('data-campaignid', trboResponse.tracking[0].campaign_id)
                                .attr('data-campaignname', trboResponse.tracking[0].campaign_name)
                                .attr('data-moduleid', trboResponse.tracking[0].module_id)
                                .attr('data-modulename', trboResponse.tracking[0].module_name)
                                .attr('data-usertype', trboResponse.tracking[0].user_type);
                        }

                        await tracking_eecPromotionview();
                    },
                    error: async () => {
                        // $(this).find(".trboBuehneDummy").remove();
                        $(this)
                            .find('.bannerItem')
                            .removeClass('hidden');
                        $(this)
                            .find('.promotionTracking')
                            .removeClass('hidden');
                        configureSwiperSlider($(this).data('frequency'), 'fade', $(this));
                        document.dispatchEvent(new Event('scroll'));
                        window.scroll(0, 1); // hack to show slider correctly
                        await tracking_eecPromotionview();
                        return;
                    },
                });
            });
        } else {
            setTimeout(handleTrboBuehnen, 100);
        }
    }
}

async function renderTrboSliders() {
    var trboSliders = $('.trbo-grid-caroussel');
    if (trboSliders.length) {
        if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
            const details = await __ucCmp.getConsentDetails();
            if (details.services[trboId].consent.given && window['_trbo'] === undefined) {
                setTimeout(renderTrboSliders, 100);
                return;
            }

            trboSliders.each(function() {
                let slot = $(this).data('slot');
                let size = $(this).data('largeslider') === 'True' ? 'Large' : 'Small';
                if (!details.services[trboId].consent.given) {
                    $(`.${slot}`)
                        .parent()
                        .addClass('hide');
                    return;
                }
                $(`.${slot}`)
                    .parent()
                    .removeClass('hide');
                const requestUrl = buildTrboRequestUrl(slot);
                $.ajax({
                    method: 'GET',
                    dataType: 'JSON',
                    url: encodeURI(requestUrl),
                    success: async data => {
                        const trboProducts = mapTrboResponseToProduktkarussellModel(data) as ProduktkarussellModel;
                        if (trboProducts.karussellItems.length === 0) {
                            $(`.${slot}`)
                                .parent()
                                .addClass('hide');
                            return;
                        }
                        CreateSliderContent(
                            trboProducts,
                            $(`#product${slot}`),
                            $('#ProduktdetailPrototypes #SliderPrototypes'),
                            `#product${slot}`,
                            true
                        );
                        //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                        if (size === 'Large') {
                            initSwiperLarge($(`#produktkarussell${size}${slot}`));
                        } else {
                            initSwiperSmall($(`#produktkarussell${size}${slot}`));
                        }

                        await tracking_eecPromotionview();
                        setTimeout(function() {
                            // nasty hack aber keinen weg gefunden das karussell auf seite 1 springt...
                            $(`#produktkarussell${size}${slot} .swiper-pagination-bullet`)
                                .first()
                                .click();
                        }, 100);
                    },
                    error: () => {
                        $(`.${slot}`)
                            .parent()
                            .addClass('hide');
                    },
                });
            });
        } else {
            setTimeout(renderTrboSliders, 100);
        }
    }
}

async function renderDynamicTrboSliders() {
    var trboSliders = $('.trboDynamicSlider');
    if (trboSliders.length) {
        if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
            const details = await __ucCmp.getConsentDetails();
            if (!details.services[trboId].consent.given) {
                if (details.services[trboId].consent.given && window['_trbo'] === undefined) {
                    setTimeout(renderDynamicTrboSliders, 100);
                    return;
                }

                trboSliders.each(function() {
                    $(this)
                        .find('.ajaxSpinner_Loading')
                        .addClass('hide');
                    $(`#product${$(this).data('guid')}`).remove();
                    if ($(this).data('size') === 'Large') {
                        initSwiperLarge($(this));
                    } else {
                        initSwiperSmall($(this));
                    }
                });
                return;
            } else {
                trboSliders.each(function() {
                    const slotName = $(this).data('trboslotname');
                    const sliderGuid = $(this).data('guid');
                    const requestUrl = buildTrboRequestUrl(slotName);
                    const size = $(this).data('size');
                    $.ajax({
                        method: 'GET',
                        dataType: 'JSON',
                        url: encodeURI(requestUrl),
                        success: async data => {
                            const trboProducts = mapTrboResponseToProduktkarussellModel(data) as ProduktkarussellModel;
                            if (trboProducts.karussellItems.length === 0 || $(`#product${sliderGuid}`).length === 0) {
                                $(this)
                                    .find('.ajaxSpinner_Loading')
                                    .addClass('hide');
                                $(`#product${sliderGuid}`).remove();
                                if (size === 'Large') {
                                    initSwiperLarge($(this));
                                } else {
                                    initSwiperSmall($(this));
                                }
                                return;
                            }

                            $(this)
                                .find('.swiper-wrapper-server')
                                .remove();
                            CreateSliderContent(
                                trboProducts,
                                $(`#product${sliderGuid}`),
                                $('#ProduktdetailPrototypes #SliderPrototypes'),
                                `#product${sliderGuid}`,
                                true
                            );
                            //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                            if (size === 'Large') {
                                initSwiperLarge($(this));
                            } else {
                                initSwiperSmall($(this));
                            }

                            await tracking_eecPromotionview();

                            setTimeout(function() {
                                // nasty hack aber keinen weg gefunden das karussell auf seite 1 springt...
                                $(this)
                                    .find(`.swiper-pagination-bullet`)
                                    .first()
                                    .click();
                            }, 100);
                        },
                        error: () => {
                            $(this)
                                .find('.ajaxSpinner_Loading')
                                .addClass('hide');
                            $(`#product${$(this).data('guid')}`).remove();
                            if ($(this).data('size') === 'Large') {
                                initSwiperLarge($(this));
                            } else {
                                initSwiperSmall($(this));
                            }
                        },
                    });
                });
            }
        }
    }
}

window.addEventListener('onConsentChanged', function(e: any) {
    if (e.detail.event === 'consent_status' && e.detail['trbo']) {
        setTimeout(handleTrboBuehnen, 1000);
    }
});
