import { searchResultClickTracking } from '../../Tracking/GoogleTracking';
import ILineItem = CheckoutflowReponse.ILineItem;
import { formatPrice2Digits } from '../../Shared/PriceHelper';

declare var dataLayer: any[];

$(() => {
    $('.FactFinderSearchResultContainer').on('click', '.factFinderTrackingItem', function() {
        var parent = $(this)
            .parents('.FFTrefferlistenProdukt')
            .get(0);
        var id = parseInt($(parent).data('id'));
        var sessionId = $(parent).data('sessionid');
        var origPos = $(parent).data('origpos');
        var query = $(parent).data('query');
        var pos = $(parent).data('pos');
        var page = $(parent).data('page');
        var pagesize = $(parent).data('pagesize');
        var simi = $(parent).data('simi');
        var factFinderCampaign = $(parent).data('factfindercampaign');

        searchResultClickTracking(query);

        $.ajax({
            method: 'GET',
            contentType: 'application/json',
            url:
                '/Umbraco/api/FactFinderTracking/ProductDetailTracking?' +
                'productId=' +
                id +
                '&sessionId=' +
                sessionId +
                '&query=' +
                query +
                '&position=' +
                pos +
                '&origPosition=' +
                origPos +
                '&page=' +
                page +
                '&pageSize=' +
                pagesize +
                '&origPageSize=' +
                pagesize +
                '&simi=' +
                simi +
                '&factFinderCampaign=' +
                factFinderCampaign,

            success: data => {},
            error: function() {},
        });
    });

    $(document).on('click', '.factFinderCartTrackingItem', function() {
        var parent = $(this).closest('.FFTrefferlistenProdukt');
        var id = parseInt($(parent).data('id'));
        var sessionId = $(parent).data('sessionid');
        var price = $(parent).data('price');

        factfinderCartTracking(id, sessionId, price, 1);
    });
});

/**
 * Ruft das FactfinderCartTracking für jedes LineItem im Warenkorb auf
 * @param lineItems
 */
export function factfinderBasketTracking(lineItems: ILineItem[]): void {
    for (let lineItem of lineItems) {
        let productId = lineItem.product.productId;
        let sessionId = $('#CurrentSessionId').val() as string;
        let price = lineItem.singleItemGrossPrice.amount;
        let count = lineItem.quantity;
        factfinderCartTracking(productId, sessionId, price, count);
    }
}

/**
 * Ruft das FactfinderCartTracking für jedes LineItem im Warenkorb auf
 * @param lineItems
 */
export function factfinderThankYouTracking(lineItems: ILineItem[]): void {
    for (let lineItem of lineItems) {
        let productId = lineItem.product.productId;
        let sessionId = $('#CurrentSessionId').val() as string;
        let price = lineItem.singleItemGrossPrice.amount;
        let count = lineItem.quantity;
        factfinderCheckoutTracking(productId, sessionId, price, count);
    }
}

/**
 * Ruft das Factfinder Cart-Tracking auf
 * @param productId
 * @param sessionId
 * @param price
 */
export function factfinderCartTracking(productId: number, sessionId: string, price: number, count: number) {
    $.ajax({
        method: 'GET',
        contentType: 'application/json',
        url:
            '/Umbraco/api/FactFinderTracking/CartTracking?' +
            'productId=' +
            productId +
            '&sessionId=' +
            sessionId +
            '&price=' +
            formatPrice2Digits(price, true),
        success: data => {},
        error: function() {},
    });
}

/**
 * Ruft das Factfinder Checkout-Tracking auf (Danke-Seite)
 * @param productId
 * @param sessionId
 * @param price
 */
export function factfinderCheckoutTracking(productId: number, sessionId: string, price: number, count: number) {
    $.ajax({
        method: 'GET',
        contentType: 'application/json',
        url:
            '/Umbraco/api/FactFinderTracking/ThankYouTracking?' +
            'productId=' +
            productId +
            '&sessionId=' +
            sessionId +
            '&price=' +
            formatPrice2Digits(price, true),
        success: data => {},
        error: function() {},
    });
}

export function factfinderSuggestTracking(area: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'Search Action',
        eventAction: 'Suggest',
        eventLabel: `${area}`,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['search data', `suggest/${area}`, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}
