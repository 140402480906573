import ProductHelper = require("../../Shared/ProductHelper");
import ICart = CheckoutflowReponse.ICart;
import ILineItem = CheckoutflowReponse.ILineItem;
import { formatPrice2Digits } from "../../Shared/PriceHelper";
import { buildVar5, getProductNetPrice } from "./checkoutTrackingHelper";
declare var dataLayer: any[];
declare var __ucCmp: any;

/**
 * führt die dataLayer-Pushes für die übergebenen LineItems aus.
 * Für Warenkorb-onload
 * @param lineItems
 */
export async function onloadTracking(cart: ICart) {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        let lineItems = cart? cart.lineItems : [];
    let products = [];
    let productIds = [];
    let productPrices = [];
    for (let lineItem of lineItems) {
        let product = lineItem.product;
        productIds.push(product.productId)
        let productNetPrice = lineItem.singleItemNetPrice ? formatPrice2Digits(getProductNetPrice(lineItem), true) : "";
        productPrices.push(productNetPrice);
        let breadcrumb: string = await ProductHelper.getBreadcrumbByTocId(product.mainTocNodeId);
        products.push(
            {
                'name': product.titleMain,
                'id': product.productId,
                'price': productNetPrice,
                'brand': product.publisher ? product.publisher.publisherName : "",
                'category': breadcrumb ? breadcrumb : "",
                'variant': product.mediaType ? product.mediaType.mediaTypeName : "",
                'quantity': lineItem.quantity,
                'dimension8': product.displayFormat

            });
    }
    dataLayer.push({
        'emosUrl': '/scripts/Econda/emos3.js',
        'pageTitle': 'Warenkorb',
        'event': 'eec.checkoutStep',
        'ecommerce': {
            'checkout': {
                'actionField': { 'step': 1 },
                'products': products
            }
        }
    });

    dataLayer.push({
        'google_tag_params': {
            'ecomm_pagetype': 'cart',
            'ecomm_prodid': productIds,
            'ecomm_pvalue': productPrices,
            'ecomm_totalvalue':(cart && cart.totalNetPrice) ? formatPrice2Digits(cart.totalNetPrice.amount, true) : ""
        }

    });

    }
    else {
        setTimeout(onloadTracking.bind(null, cart), 100);
    }
    
}



    /**
     * Führt die dataLayer-Pushes für das übergebene LineItem durch.
     * add, oder remove bei Quantity-change.
     * @param updatedLineItem
     * @param oldQuantity
     */
export async function onChangeQuantityTracking(cart: ICart, updatedLineItem: ILineItem, newQuantitiy: number, oldQuantity: number) {
        let product = updatedLineItem.product;
    let breadcrumb: string = await ProductHelper.getBreadcrumbByTocId(product.mainTocNodeId);
    let quantityChange = newQuantitiy - oldQuantity;
    const price = updatedLineItem.product.prices.filter((x) => x.priceType.priceTypeId ==
        updatedLineItem.currentPriceType.priceTypeId)[0];
        if (quantityChange > 0) {
            dataLayer.push({
                'ecProducts': [
                    {
                        type: 'c_add',
                        pid: product.productId,
                        sku: product.isbnIssn,
                        name: product.authorList !== null ?
                            product.authorList +
                            ' - ' +
                            product.titleMain : product.titleMain,
                        price: updatedLineItem.singleItemNetPrice ? formatPrice2Digits(getProductNetPrice(updatedLineItem), true) : "",
                        group: breadcrumb,
                        count: quantityChange,
                        var1: product.mediaType ? product.mediaType.mediaTypeName : "",
                        var2: product.displayFormat,
                        var3: product.publisher ? product.publisher.publisherName : "",
                        var4: product.availabilityStatusId.toString(),
                        var5: buildVar5(product),
                        var6: price?.isApproximatePrice ? "CaPreis" : "Festpreis"
                    }
                ],
                'emosUrl': '/scripts/Econda/emos3.js',
                'ecSiteId': document.head.querySelector("[name~=econdaurl][content]").getAttribute("content"),
                'ecContent': '',
                'event': 'eec.addToCart',
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'add': { // 'add' actionFieldObject measures.
                        'products': [
                            { //  adding a product to a shopping cart.
                                'name': product.titleMain,
                                'id': product.productId,
                                'price': updatedLineItem.singleItemNetPrice ? formatPrice2Digits(getProductNetPrice(updatedLineItem), true) : "",
                                'brand': product.publisher ? product.publisher.publisherName : "",
                                'category': breadcrumb,
                                'variant': product.mediaType ? product.mediaType.mediaTypeName : "",
                                'quantity': quantityChange,
                                'dimension8': product.displayFormat
                            }
                        ]
                    }
                }
            },
                {
                    'google_tag_params': {
                        'ecomm_pagetype': 'cart',
                        'ecomm_prodid': product.productId,
                        'ecomm_pvalue': updatedLineItem.singleItemNetPrice ? formatPrice2Digits(getProductNetPrice(updatedLineItem), true) : "",
                        'ecomm_totalvalue': cart.totalNetPrice ? formatPrice2Digits(cart.totalNetPrice.amount, true) : ""

                    }
                }
            );
        } else {
            dataLayer.push({
                'emosUrl': '/scripts/Econda/emos3.js',
                'ecSiteId': document.head.querySelector("[name~=econdaurl][content]").getAttribute("content"),
                'ecContent': '',
                'event': 'eec.removeFromCart',
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'remove': { // 'remove' actionFieldObject measures.
                        'products': [
                            { //  removing a product to a shopping cart.
                                'name': product.titleMain,
                                'id': product.productId,
                                'price': updatedLineItem.singleItemNetPrice ? Number.parseFloat(formatPrice2Digits(getProductNetPrice(updatedLineItem), true)) : "",
                                'brand': product.publisher ? product.publisher.publisherName : "",
                                'category': breadcrumb,
                                'variant': product.mediaType ? product.mediaType.mediaTypeName : "",
                                'quantity': Math.abs(quantityChange),
                                'dimension8': product.displayFormat
                            }
                        ]
                    }
                }
            });
        }
    }

    
